<template>
  <div class="container">
    <div class="img">
      <img src="../assets/image/dly-icon-01.png" alt="" />
    </div>
    <div class="mask">
      <div class="userBox">
        <div class="title">欢迎登录!</div>
        <div class="iptBox" :class="curIndex == 0 ? 'iptAct' : ''">
          <div class="icon">
            <img src="../assets/image/dly-icon-02.png" alt="" />
          </div>
          <el-input
            v-model="user"
            placeholder="请输入账号"
            @focus="focus(0)"
            @blur="blur()"
            @keyup.enter.native="Login"
          ></el-input>
        </div>
        <div class="iptBox" :class="curIndex == 1 ? 'iptAct' : ''">
          <div class="icon">
            <img src="../assets/image/dly-icon-03.png" alt="" />
          </div>
          <el-input
            v-model="password"
            placeholder="密码，6-18个字符组合"
            @focus="focus(1)"
            @blur="blur()"
            @keyup.enter.native="Login"
            type="password"
          ></el-input>
        </div>
        <div class="iptBox" :class="curIndex == 2 ? 'iptAct' : ''">
          <div class="icon">
            <img src="../assets/image/dly-icon-04.png" alt="" />
          </div>
          <el-input
            v-model="code"
            placeholder="请输入右侧验证码"
            @focus="focus(2)"
            @blur="blur()"
            @keyup.enter.native="Login"
          ></el-input>
          <div class="yzBox" @click="genVerifyCode">
            <img :src="assetsimages" alt="" />
          </div>
        </div>
        <div class="rememberBox" @click="toggle()">
          <div class="icon" :class="!keepPsw ? 'icon1' : ''"></div>
          <div class="name">记住密码</div>
        </div>
        <div class="button" @click="Login">登录</div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/server";
export default {
  name: "login",
  data() {
    return {
      user: "",
      password: "",
      code: "",
      curIndex: -1,
      keepPsw: true,
      assetsimages: "", //	二维码图片
      urls: "",
    };
  },

  methods: {
    focus(val) {
      this.curIndex = val;
    },
    blur() {
      this.curIndex = -1;
    },
    toggle() {
      this.keepPsw = !this.keepPsw;
    },
    //获取地址栏参数
    getRequest(parName) {
      var str = parName.toLowerCase() + "=";
      var gvalue = "";
      var HREF = location.href;
      var upperHREF = location.href.toLowerCase();
      if (upperHREF.indexOf(str) > 0) {
        gvalue = HREF.substring(
          upperHREF.indexOf(str) + str.length,
          upperHREF.length
        );
        if (gvalue.indexOf("&") > 0)
          gvalue = gvalue.substring(0, gvalue.indexOf("&"));
        if (gvalue.indexOf("#") > 0) gvalue = gvalue.split("#")[0];
        gvalue = decodeURIComponent(gvalue);
      }
      return gvalue;
    },
    // 更新二维码
    genVerifyCode() {
      Api.ajaxRequest("genVerifyCode").then((res) => {
        let { code, data } = res;
        if (code == "200") {
          this.assetsimages = res.data;
        }
      });
    },
    //配置场景样式
    GetStyleSet() {
      Api.ajaxRequest("style/get/config", {}).then((res) => {
        let { code, data } = res;
        if (code == "200") {
          console.log();
          let custId = JSON.parse(data).custId;
          localStorage.setItem("styleValObj_" + custId, data);
          setTimeout(() => {
            if (this.locationHref ?? false) {
              window.location.href = decodeURIComponent(this.locationHref);
            } else {
              this.$router.push({
                path: "/index",
              });
            }
          });
        }
      });
    },
    // 账号密码登录
    Login() {
      console.log(123123);
      if (this.user == "") {
        this.$message.error("请输入账号");
        return;
      }
      if (this.password == "") {
        this.$message.error("请输入密码");
        return;
      }
      if (this.code == "") {
        this.$message.error("请输入验证码");
        return;
      }

      let params = {
        pin: this.user,
        passcode: this.password,
        type: "account",
        verifyCode: this.code,
        syscode: this.urls,
      };
      Api.ajaxRequest("sslogin", params).then((res) => {
        let {} = res;
        if (res.code == "200") {
          this.$cookies.set("token", res.token);
          this.$cookies.set("uid", res.uid);
          if (this.keepPsw == true) {
            localStorage.setItem("psd", this.$jsEncrypt(this.password));
            localStorage.setItem("name", this.$jsEncrypt(this.user));
          } else {
            localStorage.setItem("psd", "");
            localStorage.setItem("name", "");
          }
          if (res.syscode == "admin") {
            window.location.href =
              "https://digital.mgsfjq.com/admin/#/";
          } else {
            this.GetStyleSet();
          }
        } else {
          this.$message.error(res.detail);
        }
      });
    },
  },
  created() {},
  mounted() {
    this.urls = this.getRequest("syscode");
    console.log(this.urls, "this.urls");
    this.genVerifyCode();
  },
};
</script>

<style scoped lang="less">
ul li {
  list-style: none;
}

/deep/ .block-knra17ci {
  display: none;
}

.container {
  width: 100vw;
  height: 100vh;
  background-image: url(../assets/image/dly-icon-06.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  .img {
    width: 700px;
    height: 312px;
    position: absolute;
    bottom: 17px;
    right: 164px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .mask {
    width: 782px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    right: 0;
    top: 0;
    padding: 256px 0 0 157px;
    .userBox {
      width: 412px;
      height: 451px;
      .title {
        font-size: 48px;
        letter-spacing: 5px;
        color: #ffffff;
        margin-bottom: 62px;
      }
      .iptBox {
        width: 412px;
        height: 37px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #fff;
        opacity: 0.5;
        margin-bottom: 42px;
        position: relative;
        /deep/.el-input {
          input {
            background: transparent;
            border: none;
            color: #fff !important;
          }
        }
        /deep/input:-webkit-autofill,
        textarea:-webkit-autofill,
        select:-webkit-autofill {
          -webkit-text-fill-color: #fff !important;
        }
        .yzBox {
          width: 90px;
          height: 32px;
          background-color: #4f5b37;
          position: absolute;
          right: 0;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .iptAct {
        opacity: 1;
      }
      .rememberBox {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: -20px;
        cursor: pointer;
        .icon {
          width: 20px;
          height: 20px;
          background-image: url(../assets/image/dly-icon-05act.png);
          background-size: 100% 100%;
        }
        .icon1 {
          background-image: url(../assets/image/dly-icon-05.png);
        }
        .name {
          font-size: 16px;
          color: #ffffff;
          margin-left: 10px;
        }
      }
      .button {
        width: 410px;
        height: 48px;
        background-color: #4f5b37;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #ffffff;
        cursor: pointer;
        margin-top: 58px;
      }
    }
  }
}

@media (min-height: 1000px) {
}
</style>
